.Square {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    padding: 25px;
    box-sizing: border-box;
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;

    @media only screen and (max-width: 600px) {
        padding: 15px;
        min-height: 65vh;
    }

    .nologo {
        font-size: 36px;
    }
    &-header {
        font-size: 11px;
        margin-bottom: 40px;
        span {
            padding-right: 20px;
            @media only screen and (max-width: 600px) {
                display: block;
                margin-bottom: 5px;
            }
        }
    }

    &-synopsis {
        width: 60%;
        font-size: 21px;

        @media only screen and (max-width: 600px) {
            font-size: 14px;
            width: 100%;
            margin-bottom: 25px;
        }
    }

    &-logo {
        img {
            // max-height: 150px;
            max-width: 80%;

            @media only screen and (max-width: 600px) {
                max-height: 100px;
            }
        }
        min-height: 30%;

        @media only screen and (max-width: 600px) {
            min-height: 180px;
        }
    }

    &-link {
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
            color: #000;
        }

        @media only screen and (max-width: 600px) {
            font-size: 10px;
        }
    }
    &:nth-child(odd) {
        // border-top: 1px solid #000;
        border-left: 1px solid #000;
        border-right: 1px solid #000;
        border-bottom: 1px solid #000;
    }
    &:nth-child(even) {
        // border-top: 1px solid #000;
        border-left: 0;
        border-right: 1px solid #000;
        border-bottom: 1px solid #000;
    }

    &:first-child {
        border-top: 1px solid #000;
    }
    &:nth-child(2) {
        border-top: 1px solid #000;
    }
}
